<template>
  <v-container>
    <v-row class="mt-1">
      <v-col>
        <v-card>
          <v-toolbar
            class="ml-16 v-sheet--offset mx-auto"
            height="40"
            dense
            dark
            shaped
            color="green"
          >
            <h3>Oportunidades ganadas</h3>
            <v-icon
              class="ml-1 mb-1"
              large
            >
              mdi-check
            </v-icon>

            <v-spacer />
            <v-btn
              align="right"
              color="orange"
              dark
              small
              class="ma-1"
              @click="regresar"
            >
              <v-icon>mdi-reply</v-icon>
            </v-btn>
          </v-toolbar>

          <v-row>
            <v-col>
              <v-data-table
                heigth="400"
                :headers="headers"
                :items="misoportunidades"
                :search="search"
                dense
              >
                <template v-slot:item="{ item }">
                  <tr @dblclick="editar(item)">
                    <td>{{ item.idoportunidad }}</td>
                    <td>{{ item.asunto }}</td>
                    <td>{{ item.obs }}</td>
                    <td>{{ item.creada }}</td>
                    <td>{{ item.estatus }}</td>
                    <td>{{ item.nomcont }}</td>
                    <td>{{ item.nomuser }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      search: '',
      misoportunidades: [],
      headers: [
        { text: 'Id.', value: 'idoportunidad' },
        { text: 'Título', value: 'asunto' },
        { text: 'Descripción de Oportunidad', value: 'obs' },
        { text: 'Creada', value: 'creada' },
        { text: 'Estatus', value: 'estatus' },
        { text: 'Contacto', value: 'nomcont' },
        { text: 'Asesor', value: 'numuser' },
      ],
    }
  },
  created(){
    this.consultar()
  },

  methods: {
      
    consultar(){
      // limpiar
      this.misoportunidades= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      this.$http.get("v2/oportunidad.list" ).then(response=>{
        if (response.data.error===null){
          this.misoportunidades = response.data.result
            
          this.misoportunidades.forEach( element => {
            let cFecha =new Date(element.creada).toLocaleDateString(
              'es-MX', {
                timeZone: "UTC",
                month: 'short',
                day: 'numeric'
              }
            )
            element.creada = cFecha
            // element.fechastart = element.fechastart.substr(0,10)
            // element.hora = element.fechastart.substr(11,17)
          })

          console.log("oportunidades", this.misoportunidades)
        }
          
      }).catch(error=>{
        	console.log(error)
      })
    },
      
    editar(item){
      console.log("Renglonitem", item)
      this.$router.push({name:'oportunidad', params: {oportunidad:item}})
    },
    regresar(){
      console.log("ruta", this.$route.name)

      // MODO CATALOGO
      if (this.$route.name == 'ganadas' ){
        this.$router.push({name:'catoportunidades'})
      }
    },
  }
    
}

</script>

<style>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>